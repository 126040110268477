// Eigenes CSS
import '../css/style.scss';

// Einfach mal hallo sagen
//console.log('Hallo Welt');

import 'jquery/dist/jquery';
const $ = require('jquery');
//import 'superfish/src/js/superfish';
//import 'mmenu-js/src/mmenu';
//import 'slideout/dist/slideout';
import 'zeynepjs'
import 'jquery-match-height/dist/jquery.matchHeight-min';
//import 'swiper/swiper-bundle';
import Swiper, { Navigation, Pagination, Autoplay, Lazy } from 'swiper';
import 'jquery-ui/ui/position';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/collapse';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
//import Masonry from 'masonry-layout';
import 'masonry-layout/dist/masonry.pkgd';
import 'imagesloaded/imagesloaded';
import Plyr from 'plyr';
const player = new Plyr('#player');


//import 'resize-observer/dist/resize-observer';

//import Chart from 'chart.js/auto';

// import Chart from 'chart.js/auto';



//var fd_page = document.getElementsByTagName("html")[0];
//fd_page.style.opacity = "0";

/****************  INIT  *****************************/

var App = function ( ) {

    function initPageload() {
        //console.log("Pageload");
        //jQuery('html').css("opacity", "0.8");
        jQuery('html').fadeTo(300, 1);
    }

    function initSuperfish() { 
        //console.log("Superfish");
       // jQuery('ul.wpb-sf-menu').css('background-color','#ffff00');
       // jQuery('.fd-header-main-navigation ul').css('background-color','#ddff00');
       // jQuery('#wpb--menu').superfish({});
        /*
        jQuery('.wpb-fdtest').superfish({
            delay:       1000,                            // one second delay on mouseout
            animation:   {opacity:'show',height:'show'},  // fade-in and slide-down animation
            speed:       'fast',                          // faster animation speed
            cssArrows: false
        });
        */
    }

    function initMmenu() {
        new Mmenu( "#my-menu" );
        //, {
            // options
            /*
            "extensions": [ 
                "theme-dark",
                "pagedim-black"
            ],
            */
           /*
            navbar: {
                title: "title" // "10.000 Schritte Düsseldorf"
            }
        }, {
            // configuration
            offCanvas: {
                page: {
                    selector: "#ukd10k-page"
                }
            }
        });
        */
    }

    function initShrinkedHeader() {
        let shrink_active = false;
        jQuery(window).scroll(function () {
            var windowwidth = jQuery(window).width();
            //console.log("windowwidth: " + windowwidth);
         //   if (windowwidth > 750) {
             /*
                if (jQuery(window).scrollTop() > 75) {
                    jQuery('#fd-header').addClass('header-preshrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-preshrinked');
                }
                */
                if (jQuery(window).scrollTop() > 75) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    //console.log("shrinked");
                } else if (jQuery(window).scrollTop() < 25) {
                    jQuery('#fd-header').removeClass('header-shrinked');
                }

                if (jQuery(window).scrollTop() > 75) {
                    jQuery('#fd-page').addClass('main-shrinked');
                } else if (jQuery(window).scrollTop() < 25) {
                    jQuery('#fd-page').removeClass('main-shrinked');
                }



                /*
                if (jQuery(window).scrollTop() > 35) {
                    shrink_active = true;
                    
                } else if (jQuery(window).scrollTop() < 5) {
                    shrink_active = false;
                    
                }
                if ( shrink_active ) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    jQuery('#fd-page').addClass('main-shrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-shrinked');
                    jQuery('#fd-page').removeClass('main-shrinked');
                }
                */
          //  }
        });

    }

    function initRefreshAfterResize() {
        var dwidth = jQuery(window).width();
        jQuery(window).bind('resize', function(e){
            var wwidth = jQuery(window).width();
            if(dwidth!==wwidth){
            dwidth = jQuery(window).width();
            if (window.RT) clearTimeout(window.RT);
            window.RT = setTimeout(function(){
                this.location.reload(false); /* false to get page from cache */
            }, 1000);
        }
        });
    }

    function initMatchHeight() {
        //console.log("match Height");
        //jQuery(' .news-teaser-3col > div ').matchHeight({byRow: true});
        //jQuery(' .post ').matchHeight({byRow: true});
        jQuery(' #home-introteaser-col .txt ').matchHeight({byRow: true});
    }


    function initTooltips() {
        jQuery(function () {
            //jQuery('[data-toggle="tooltip-right"]').tooltip({placement: 'right', html: true});
            //jQuery('[data-toggle="tooltip-top"]').tooltip({placement: 'top', html: true, trigger: 'hover'});
            //jQuery('[data-toggle="tooltip-left"]').tooltip("show");
            jQuery('[data-toggle="tooltip"]').tooltip({ trigger: 'hover', html: true, animated: 'fade', delay: { "show": 0, "hide": 100 }, offset: '0, 10'}); 
           // jQuery('[data-toggle="tooltip"]').tooltip("show");  
        })
    }



    function initSwiperNews() {
        var newsSwiper = new Swiper('.posts-row', {
            slidesPerView: 1,
            spaceBetween: 10,
            breakpoints: {
                // when window width is >= 320px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 10
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  }
              },
            loop: true,
            grabCursor: true,
            on: {
                init: function () {
                  console.log('swiper initialized');
                  initMatchHeight();
                },
            },
        })
    }


    function initCookiesreopen() {
        jQuery('.cookiesreopen a').attr("href","javascript: Cookiebot.renew()");
    }


    function initCF7Styling() {
        var spans = document.getElementsByClassName("wpcf7-form-control-wrap");
        for(var i=0;i<spans.length;i++)
        {
            var input = spans[i].nextSibling;
            if(input != null)
            {
                if(input.tagName == "LABEL")
                {
                    spans[i].appendChild(input);
                }   
            }   
        }

        let dstext = jQuery(".wpcf7-checkbox .wpcf7-list-item-label").text();
        dstext = dstext.replace("Datenschutzerklärung", "<a href='/datenschutz/'>Datenschutzerklärung</a>");
        console.log(dstext);
        jQuery(".wpcf7-checkbox .wpcf7-list-item-label").html(dstext);
    }




    function initZeynep() {
        var zeynep = jQuery('.zeynep').zeynep({
            load: function (element, options) {
                //console.log('zeynepjs menu is successfully loaded')
              },
            onClosed: function () {
              // enable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "");
        
              console.log('the <a href="https://www.jqueryscript.net/tags.php?/side menu/">side menu</a> is closed.');
            },
            onOpened: function () {
              // disable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "pointer-events: none;");
        
              console.log('the side menu is opened.');
            }
            
        });

        jQuery(".mburger").click(function () {
            if (jQuery("html").hasClass("zeynep-opened")) {
              zeynep.close();
            } else {
              zeynep.open();
            }
        });

        jQuery(".zeynep-overlay").click(function () {
            zeynep.close();
        });
        jQuery("#mobileMenuClose-inner").click(function () {
            zeynep.close();
        });
    }


    function initSwiperSlider() {
        var swiper = new Swiper(".fdSwiper", {
            modules: [Navigation, Pagination, Autoplay, Lazy],
            loop: 'true',
            autoHeight: 'true',
            preloadImages: false,
            lazy: {
                checkInView: false,
                loadPrevNext: true,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: 'true',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              },
            autoplay: {
                delay: 4000,
            },
        });
    }

    

    function LottieScrollTrigger(vars) {
        let playhead = {frame: 0},
            target = gsap.utils.toArray(vars.target)[0],
            speeds = {slow: "+=2000", medium: "+=1000", fast: "+=500"},
            st = {trigger: target, pin: true, start: "top top", end: speeds[vars.speed] || "+=1000", scrub: 1},
            animation = lottie.loadAnimation({
                container: target,
                renderer: vars.renderer || "svg",
                loop: false,
                autoplay: false,
                path: vars.path
            });
        for (let p in vars) { // let users override the ScrollTrigger defaults
            st[p] = vars[p];
        }
        animation.addEventListener("DOMLoaded", function() {
            gsap.to(playhead, {
          duration: vars.duration || 0.5,
          delay: vars.delay || 0,
                frame: animation.totalFrames - 1,
                ease: vars.ease || "none",
                onUpdate: () => animation.goToAndStop(playhead.frame, true),
                scrollTrigger: st
            });
        // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
        ScrollTrigger.sort();
        ScrollTrigger.refresh(); 
        });
      return animation;
    }
    /*
    if ( jQuery("body").hasClass("elretiro-template") ) {
        LottieScrollTrigger({
        target: "#testlottie",
        path: '/content/themes/elretiro/src/img/lottie/lage_01.json',
        speed: 'medium',
        scrub: 2 // seconds it takes for the playhead to "catch up"
        // you can also add ANY ScrollTrigger values here too, like trigger, start, end, onEnter, onLeave, onUpdate, etc. See https://greensock.com/docs/v3/Plugins/ScrollTrigger
    });
    }
    

    function init_fd_lottie() {
        console.log("lottie");
        var animation = bodymovin.loadAnimation({
            container: document.getElementById('fdlc'),
            path: '/content/themes/elretiro/src/img/lottie/lage_01.json',
            renderer: 'svg',
            loop: false,
            autoplay: false,
            name: "FD Animation",
            rendererSettings: {
                id: 'fdlottieid',
                className: 'fdlottieclass',
                
            }
        });
    }
    */
    function initphotoswipe() {
        const lightbox = new PhotoSwipeLightbox({
            gallery: '#swipegallery',
            children: 'a',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();
    }

    function initMasonry() {

        //var $msnry = new Masonry( '#swipegallery', {
        var $msnry = $('#swipegallery').masonry({
            itemSelector: '.grid-item',
            percentPosition: true,
            columnWidth: '.grid-sizer'
          });

          //$msnry.masonry();

         // $msnry.hide();

        //let container = jQuery('#swipegallery');
        
        $msnry.imagesLoaded().progress( function() {
            $msnry.masonry();
          });
        

    };

    return {
        init: function ($) {
            initPageload();
           // initSuperfish();
            //initMmenu(); 
            ////initRefreshAfterResize();
            initShrinkedHeader();
            initMatchHeight();
            initSwiperNews();
            initCF7Styling();
            ////initCookiesreopen();
            initTooltips();
            initZeynep();
            initSwiperSlider();
            initphotoswipe();
            initMasonry();
          //  init_fd_lottie();
           // LottieScrollTrigger(); 
        }
    }
}(jQuery);


jQuery(document).ready(function () {
    //console.log('ready  ??');
    App.init();
});



